import React, { useState, useEffect,useRef } from "react";
import { Typography, Spin, message } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { makeApiRequest } from "../../helpers/data";
import Markdown from "react-markdown";
import html2pdf from "html2pdf.js";
import logo from "../../images/logo-header.png";
import summaryLogo from "../../images/summary-logo.svg";
import { CircularProgressbar } from 'react-circular-progressbar';
import "../../styles/FinalSummary.scss";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import loadergif from "../../images/loader.gif";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentModal from "./PaymentModal"; // your custom checkout form
import logout from "../../images/logout.svg";
import ProgressBar from 'react-bootstrap/ProgressBar';
const { Title, Paragraph } = Typography;


const FinalSummary = ({ report }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [percentage, setPercentage] = useState(0);
  // console.log(report?.report?.Report)
  const { answers, id } = state || {};
  const [reportType,setReportType] = useState("")
  const userId = JSON.parse(localStorage.getItem("id"));
  const [summaryData, setSummaryData] = useState(report?.report?.Report || "");
  const [showMailButton, setShowMailButton] = useState(true);
  const [loading, setLoading] = useState(true); // Set initial loading state to false
  const [countdown, setCountdown] = useState(300); // 180 seconds = 3 minutes
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { organizationName } = state || {};
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [recomendation,setRecomendation] = useState("");
  const apiCalled = useRef(false);
  const handleShow = (get) => {
    console.log(get)
    setShow(true);
    setReportType(get)
    // navigate("/finalSummaryII")
  };
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const loadStripeInstance = async () => {
      const stripe = await loadStripe(
        "pk_test_51JyxVVEOzkB81rfAEISd4WanV1Zdj8e6D2ks5QNJUbcmjZyh8HYncpiV1RPJRqeHcSh5tBcdSZhlPdX50EXozF2z00ml73iUBn"
      );
      setStripePromise(stripe);
    };

    loadStripeInstance();
  }, []); // Only run on mount

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          clearInterval(interval);
        }
        return prevCountdown - 1;
      });
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let intervalId;
    const startTime = Date.now(); // Track when the loader starts

    // Function to simulate progress
    const updateProgress = () => {
      const elapsedTime = Date.now() - startTime;

      // Calculate progress based on the elapsed time relative to 2 minutes (120000ms)
      const progress = Math.min(Math.floor((elapsedTime / 100000) * 100), 99); // Ensure it doesn't go over 95% until the API response is complete
      setPercentage(progress); // Update the progress state
    };

    // Start updating progress at regular intervals (e.g., every 1000ms or 1 second)
    intervalId = setInterval(updateProgress, 1000); // Update every 1 second for smoother increments

    // Function to generate the final summary
    const generateFinalSummary = async () => {
      if (apiCalled.current) return; // Check if the API was already called
      apiCalled.current = true; // Set the flag to true to prevent future calls

      try {
        const finalResponse = await makeApiRequest([`llm_eng/getReport/${id}`], "GET");
        setRecomendation(finalResponse?.data?.report_id);
        // Once the response is received, calculate the response duration
        const endTime = Date.now(); // Track when the response is received
        const responseTime = endTime - startTime; // Calculate the API response duration

        console.log(`API response time: ${responseTime} ms`);

        // Handle the response based on the data received
        if (finalResponse?.data) {
          if (report?.report?.Report) {
            setSummaryData(report?.report?.Report);
          } else if (finalResponse?.data?.Status === "FAILED") {
            setSummaryData("# No report found for this user. Please try again.");
          } else {
            setSummaryData(finalResponse?.data.Report);
            setShowMailButton(true);
          }
        }
      } catch (error) {
        console.error("Error generating final summary:", error);
      } finally {
        clearInterval(intervalId); // Stop the progress simulation once the response is received
        setPercentage(100); // Complete the progress bar
        const minimumTime = Math.max(2000 - (Date.now() - startTime), 0); // Ensure modal stays open for at least 2 seconds

        setTimeout(() => {
          setLoading(false); // Close the modal after the minimum time
        }, minimumTime); // Ensure smooth transition for minimum time
      }
    };


    if(summaryData === ""){
      generateFinalSummary();
    }
    else{
      setLoading(false)
    }

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, []);
  
  
  
  
  
  
  

  const exportToPDF = () => {
    const element = document.getElementsByClassName("pdf-report")[0];
  
    html2pdf(element, {
      margin: 0.50,
      filename: `cyber-security-report.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      // pagebreak: {
      //   mode: ["avoid-all", "css", "legacy"],
      //   before: ['.page-break'],  // Apply breaks before elements with this class
      //   after: ['.section-end'],  // Ensure no break within large sections
      //   avoid: ['h3', 'p']  // Avoid breaking in headings or paragraphs
      // },
    });
  };

  const handleSendEmail = () => {
    // Simulate email sending
    setTimeout(() => {
      setIsEmailSent(true);
      message.success("Email sent!");
    }, 1000);
  };

  return (
    <>
      <header className="veriti-header">
        <Link to="/welcome">
          <img className="img-fluid" src={logo} />
        </Link>

        <ul className="veriti-stepper">
          <li>
            <span>1</span>
            <h5>Company Summary</h5>
          </li>
          <li>
            <span>2</span>
            <h5>Questionnaire 1</h5>
          </li>
          <li>
            <span>3</span>
            <h5>Questionnaire 2</h5>
          </li>
          <li className="active">
            <span>4</span>
            <h5>Cybersecurity Report</h5>
          </li>
        </ul>
        <Link to="/login"><img className="img-fluid" src={logout} /></Link>
        
      </header>
      {
  loading && (
    <Modal show={loading} centered>
      <Modal.Body>
        <h3>Generating Cybersecurity Report</h3>
        <p>Please wait...</p>
        <div className="mt-4">
        <ProgressBar animated now={percentage}/>
          {/* <CircularProgressbar value={percentage} text={`${percentage}%`} /> */}
        </div>
      </Modal.Body>
    </Modal>
  )
}
      {!loading && (
        <>
          <div className="veriti-section-wrapper veriti-summary-wrapper">
            <div className="veriti-section-inner">
              <div className="container mb-4">
                <div className="content-box p-0">
                  <div className="final-summary-container-new editable-summary-container position-relative">
                    <img src={summaryLogo} class="summary-logo" alt="logo" />
                    <div className="pdf-report">
                      <Markdown>{summaryData}</Markdown>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="content-box p-0">
                  <div
                    className="button-container btn-container-secondary
"
                  >
                    <div className="btn-wrapper">
                      {showMailButton && (
                        <>
                          <Button
                            type="primary"
                            title="Email me the full report"
                            className="btn-veritig-white  ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary btn-veriti"
                            onClick={handleSendEmail}
                          >
                            {isEmailSent
                              ? "Email sent ✔ "
                              : "Email me the full report"}
                            <span className="mail"></span>
                          </Button>
                          <Button
                            type="primary"
                            title="Download the Full Report"
                            className="btn-veritig-white  ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary btn-veriti"
                            onClick={exportToPDF}
                          >
                            Download the Full Report
                            <span className="download-white"></span>
                          </Button>
                        </>
                      )}
                    </div>
                    <div className="btn-wrapper ">
                      {showMailButton && (
                        <>
                          <Button
                            type="primary"
                            className="btn-veritig-white  ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary btn-veriti"
                            onClick={()=>{handleShow(true)}}
                          >
                            Generate Detailed Recommendation Report
                            <span className="report"></span>
                          </Button>
                          <Button
                            type="primary"
                            className="btn-veritig-white  ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary btn-veriti"
                            onClick={()=>{handleShow(false)}}
                          >
                            Create an IT Policy Manual
                            <span className="manual"></span>
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <></>
          </div>
          <Elements stripe={stripePromise}>
            <PaymentModal show={show} reportType={reportType} handleClose={handleClose} recomendation={recomendation || report?.recommendation_id}/>
          </Elements>
          {/* <Modal show={show} onHide={handleClose} centered>
  <Modal.Body>
    <h3>Generate Detailed Recommendation Report</h3>
    <p>Please Wait...</p>
    <img src={successgif} alt="success" className="img-fluid success-gif loader-gif" />
  </Modal.Body>
</Modal> */}
        </>
      )}
    </>
  );
};

export default FinalSummary;
