import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'antd/dist/reset.css';
import 'react-circular-progressbar/dist/styles.css';
import './App.css';
import InitiateLogin from './components/login/index'
import PasswordCretion from './components/login/passwordCreation';
import Login from './components/login/login'
import Questionnaire from './components/display/questionnaire';
import Welcome from './components/display/welcome';
import EditableSummary from './components/display/summary';
import QuestionnaireII from './components/display/questionnaireII';
import FinalSummary from './components/display/FinalSummary';
import ClearData from './components/login/clearData';
import 'bootstrap/dist/css/bootstrap.min.css';
import ForgotPassword from './components/login/forgotPassword';
import SelectPlan from './components/display/SelectPlan';
import FinalSummaryII from './components/display/FinalSummaryII';
import FinalSummaryIII from './components/display/FinalSummaryIII';
const App = () => {
  const [report,setReport] = useState({})
  const reportHandler = (data) => {
    setReport(data)
    console.log(data);
  }
  return (
    <Router>
      <Routes>
        <Route path="/" element={<InitiateLogin />} />
        <Route path="/setPassword/:id" element={<PasswordCretion />} />
        <Route path="/login" element={<Login />} />
        <Route path="/clear" element={<ClearData />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/selectPlan" element={<SelectPlan />} />
        <Route path="/questionnaire" element={<Questionnaire />} />
        <Route path="/welcome"  element={<Welcome reportHandler={reportHandler} />} />
        <Route path="/summary" element={<EditableSummary />} />
        <Route path="/questionnaireII" element={<QuestionnaireII />} />
        <Route path="/finalSummary" element={<FinalSummary  report={report}/>} />
        <Route path="/finalSummaryII/:recommendationId" element={<FinalSummaryII report={report}/>} />
        <Route path="/finalSummaryIII" element={<FinalSummaryIII />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
};

export default App;
