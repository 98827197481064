import React, { useEffect, useState } from "react";
import { Input, Button, message, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import "antd/dist/reset.css";
import "../../styles/passwordCreation.scss";
import { isValidPassword } from "../../helpers/utils";
import { makeApiRequest } from "../../helpers/data";
import logo from "../../images/logo.png";
import { Form } from "react-bootstrap";

const PasswordCreation = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedId = JSON.parse(localStorage.getItem("id"));
    if (storedId) {
      localStorage.removeItem("id");
    }
    localStorage.setItem("id", JSON.stringify(id));
  }, [id]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = () => {
    if (!isValidPassword(password, confirmPassword)) {
      message.error("Invalid password or passwords do not match");
      message.error("Minimum 8 characters with 1 uppercase");
      return;
    }

    setLoading(true); // Set loading to true when submitting

    const promise = new Promise((resolve, reject) => {
      makeApiRequest([`auth/setPassword/${id}`], "PUT", { password })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
        .finally(() => setLoading(false)); // Set loading to false regardless of success or failure
    });

    promise.then((res) => {
      if (res?.response?.status === 200) {
        navigate("/login");
      }
    });
  };

  return (
    <>
      <div className="login-container veriti_login--wrapper">
        {loading ? (
          <Spin size="large" />
        ) : (
          <>
            <div className="veriti_login--inner">
              <h1>
                <img src={logo} alt="logo" className="img-fluid" />
              </h1>
              <h3>Create Password</h3>
              <p>Create a password for your account.</p>
              <div className="form-container">
                <Form.Group
                  className="form-group"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Enter Password</Form.Label>
                  <Form.Control
                    placeholder="Password"
                    type="password"
                    onChange={handlePasswordChange}
                    className="input-field"
                  />
                </Form.Group>
                <Form.Group
                  className="form-group"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    placeholder="Confirm Password"
                           type="password"
                    onChange={handleConfirmPasswordChange}
                    className="input-field"
                    onKeyDown={(e) => {
                      if (e.code === "Enter") {
                        handleSubmit();
                      }
                    }}
                  />
                </Form.Group>
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  className="submit-button btn-veriti"
                >
                  Submit
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PasswordCreation;
