import React, { useState, useEffect } from "react";
import { Typography, Spin, message } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { makeApiRequest } from "../../helpers/data";
import Markdown from "react-markdown";
import html2pdf from "html2pdf.js";
import logo from "../../images/logo-header.png";
import summaryLogo from "../../images/summary-logo.svg";
import axios from 'axios';
import "../../styles/FinalSummary.scss";
import { useParams } from 'react-router-dom';
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import loadergif from "../../images/loader.gif";
import logout from "../../images/logout.svg";
import ProgressBar from 'react-bootstrap/ProgressBar';
const { Title, Paragraph } = Typography;

const FinalSummaryII = ({report}) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [percentage, setPercentage] = useState(0);
  const { answers, id } = state || {};
  const userId = JSON.parse(localStorage.getItem("id"));
  const [summaryData, setSummaryData] = useState(report.response_text || "");
  const [showMailButton, setShowMailButton] = useState(true);
  const [loading, setLoading] = useState(true); // Set initial loading state to false
  const [countdown, setCountdown] = useState(300); // 180 seconds = 3 minutes
  const [isEmailSent, setIsEmailSent] = useState(false);
  // const { organizationName } = state;
  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          clearInterval(interval);
        }
        return prevCountdown - 1;
      });
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const { recommendationId } = useParams();
  const recommendationIdNumber = Number(recommendationId);

useEffect(() => {
  let intervalId; // Variable to store the interval ID for progress simulation
  const startTime = Date.now(); // Track when the loader started

  const updateProgress = () => {
    const elapsedTime = Date.now() - startTime;
    const progress = Math.min(Math.floor((elapsedTime / 30000) * 100), 99); // Cap at 95% for simulated progress
    setPercentage(progress); // Update the progress state
  };

  // Start updating progress at regular intervals (e.g., every 1000ms or 1 second)
  intervalId = setInterval(updateProgress, 1000); // Update every 1 second

  const generateFinalSummary = async () => {
    try {
      const response = await axios.post('http://64.23.148.248:8000/llm_eng/getRecommendationReport', {
        user_id: userId,
        recommendation_id: report?.recommendation_id,
      });

      // Handle the response based on the data received
      if (response.data) {
        if (response.data.Status === "FAILED") {
          setSummaryData("# No report found for this user. Please try again.");
          return;
        }
        setSummaryData(response.data.report.Recommendations);
        setShowMailButton(true);
      }
    } catch (error) {
      console.error("Error generating final summary:", error);
    } finally {
      clearInterval(intervalId); // Stop the progress simulation once the response is received
      setPercentage(100); // Complete the progress bar
      const minimumTime = Math.max(2000 - (Date.now() - startTime), 0); // Ensure modal stays open for at least 2 seconds

      setTimeout(() => {
        setLoading(false); // Close the modal after the minimum time
      }, minimumTime); // Delay closing the modal if necessary
    }
  };

  if (summaryData === "") {
    generateFinalSummary(); // Call the asynchronous function to initiate the API request
  }
  else{
    setLoading(false)
  }

  // Cleanup interval on unmount
  return () => clearInterval(intervalId);
}, [userId, recommendationId]); // Add userId and recommendationId as dependencies


  const exportToPDF = () => {
    const element = document.getElementsByClassName("pdf-report")[0];
  
    html2pdf(element, {
      margin: 0.75,
      filename: `recommendation-report.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      pagebreak: {
        mode: ["avoid-all", "css", "legacy"],
        before: ['.page-break'],  // Apply breaks before elements with this class
        after: ['.section-end'],  // Ensure no break within large sections
        avoid: ['h3', 'p']  // Avoid breaking in headings or paragraphs
      },
    });
  };
  

  const handleSendEmail = () => {
    // Simulate email sending
    setTimeout(() => {
      setIsEmailSent(true);
      message.success("Email sent!");
    }, 1000);
  };

  return (
    <>
      <header className="veriti-header">
        <Link to="/welcome"><img className="img-fluid" src={logo} /></Link>
        <Link to="/login"><img className="img-fluid" src={logout} /></Link>
      </header>
      {loading && (
        <Modal show={loading} centered>
          <Modal.Body>
            <h3>Generating Recommendation Report</h3>
            <p>Please wait...</p>
            {/* <h5>
              {countdown}
              <span> sec</span>
            </h5> */}
            <div className="mt-4">
        <ProgressBar animated now={percentage}/>
          {/* <CircularProgressbar value={percentage} text={`${percentage}%`} /> */}
        </div>
          </Modal.Body>
        </Modal>
      )}
      {!loading && (
        <>
          <div className="veriti-section-wrapper veriti-summary-wrapper">
            <div className="veriti-section-inner">
              <div className="container mb-4">
                <div className="content-box p-0">
                  <div className="final-summary-container-new final-recomendation-container editable-summary-container position-relative">
                    <img src={summaryLogo} class="summary-logo" alt="logo" />
                    <div className="pdf-report"><Markdown >{summaryData}</Markdown></div>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="content-box p-0">
                  <div
                    className="button-container justify-content-end btn-container-secondary
"
                  >
                    <div className="btn-wrapper">
                      {showMailButton && (
                        <>
                          <Button
                            type="primary"
                            title="Email me the full report"
                            className="btn-veritig-white  ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary btn-veriti"
                            onClick={handleSendEmail}
                          >
                            {isEmailSent
                              ? "Email sent ✔ "
                              : "Email me the full report"}
                            <span className="mail"></span>
                          </Button>
                          <Button
                            type="primary"
                            title="Download the Full Report"
                            className="btn-veritig-white  ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary btn-veriti"
                            onClick={exportToPDF}
                          >
                            Download the Full Report
                            <span className="download-white"></span>
                          </Button>
                        </>
                      )}
                    </div>
                    <div className="btn-wrapper d-none">
                      {showMailButton && (
                        <>
                          <Button
                            type="primary"
                            className="btn-veritig-white  ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary btn-veriti"
                          >
                            Generate Detailed Recommendation Report
                            <span className="report"></span>
                          </Button>
                          <Button
                            type="primary"
                            className="btn-veritig-white  ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary btn-veriti"
                          >
                            Create an IT Policy Manual
                            <span className="manual"></span>
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <></>
          </div>
        </>
      )}
    </>
  );
};

export default FinalSummaryII;
