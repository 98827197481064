import React, { useEffect, useState } from "react";
import { Button, Checkbox, Row, Col, Input, Spin } from "antd";
import loadergif from "../../images/loader.gif";
import Modal from "react-bootstrap/Modal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../styles/questionnaire.scss";
import logo from "../../images/logo-header.png";
import logout from "../../images/logout.svg";
import { makeApiRequest } from "../../helpers/data";
import ProgressBar from 'react-bootstrap/ProgressBar';
const QuestionnaireII = () => {
  const { state } = useLocation();
  const { ques } = state || {};
  const [questions, setQuestions] = useState(ques || {});
  const [answers, setAnswers] = useState({});
  const [otherAnswers, setOtherAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [newQuestion, setNewQuestion] = useState("");
  const [newOptions, setNewOptions] = useState([]);
  const id = JSON.parse(localStorage.getItem("id"));
  const [loading, setLoading] = useState(false);
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const [confidenceScore, setConfidenceScore] = useState();
  const [questionCount, setQuestionCount] = useState(1);
  const [countdown, setCountdown] = useState(180);
  const [percentage, setPercentage] = useState(0);
  const [intervalId, setIntervalId] = useState(null); 
  
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          clearInterval(interval);

          //setCountdown(180);
        }

        return prevCountdown - 1;
      });
    }, 1000);

    // Clear the interval when the component unmounts

    return () => clearInterval(interval);
  }, []);

  
  useEffect(() => {
    // Additional logic or effects based on the questions state
  }, [questions]);

  const handleCheckboxChange = (option) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [Object.keys(questions)[0]]: [
        ...(prevAnswers[Object.keys(questions)[0]] || []),
        option,
      ],
    }));
  };



  const handleNext = async () => {
    try {
      setLoading(true); // Set loading to true when making the request
      setPercentage(0); // Reset progress bar at the beginning

      let updatedAnswers = { ...answers };
      const otherQuestions = Object.keys(otherAnswers);

      if (otherQuestions && otherQuestions.length > 0) {
        // Replace the 'Other' option with the user's response and save it in updatedAnswers
        otherQuestions.forEach((question) => {
          const otherOptionIndex = updatedAnswers[question].indexOf("Other");
          updatedAnswers[question][otherOptionIndex] = otherAnswers[question];
        });
      }

      const startTime = Date.now(); // Track when the request starts

      // Simulate progress while waiting for the response
      const interval = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const progress = Math.min(Math.floor((elapsedTime / 180) * 100), 99); // Simulated 3 minutes max
        setPercentage(progress); // Update progress bar
      }, 1000); // Update every second

      // Store the interval ID to clear it later
      setIntervalId(interval);

      const response = await makeApiRequest(
        ["llm_eng/finalQuestionnaire"],
        "POST",
        {
          user_id: id,
          response: JSON.stringify(updatedAnswers),
        }
      );

      setAnswers({});
      setOtherAnswers({});
      setQuestionCount((prevCount) => prevCount + 1);

      if (response?.data) {
        setQuestions(response.data?.response);

        if (isLastQuestion) {
          generateFinalSummary();
          return;
        }

        if (response?.data?.confidence_score) {
          setConfidenceScore(response?.data?.confidence_score);
        }

        if (response?.data?.is_last_question) {
          setIsLastQuestion(true);
        } else {
          setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        }
      }
    } catch (error) {
      console.error("Error fetching next question:", error);
    } finally {
      clearInterval(intervalId); // Stop the progress simulation when the request is complete
      setPercentage(100); // Complete the progress bar
      setLoading(false); // Set loading back to false after the request is complete
    }
  };

  const handlePrev = async () => {
    setQuestionCount((prevCount) => prevCount - 1);
  };

  const handleRemoveQuestion = () => {
    const updatedQuestions = { ...questions };
    delete updatedQuestions[Object.keys(questions)[0]];
    setQuestions(updatedQuestions);

    // Remove associated answers when removing a question
    setAnswers((prevAnswers) => {
      const updatedAnswers = { ...prevAnswers };
      delete updatedAnswers[Object.keys(questions)[0]];
      return updatedAnswers;
    });
  };

  const handleAddQuestion = () => {
    setModalVisible(true);
  };

  const handleSaveQuestion = () => {
    if (newQuestion.trim() !== "" && newOptions.length > 0) {
      setQuestions({
        [newQuestion]: newOptions,
      });
      setModalVisible(false);
      setNewQuestion("");
      setNewOptions([]);
    }
  };

  const handleAddOption = () => {
    setNewOptions((prevOptions) => [...prevOptions, ""]);
  };

  const handleOptionChange = (index, value) => {
    setNewOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = value;
      return newOptions;
    });
  };

  const generateFinalSummary = () => {
    navigate("/finalSummary", { state: { answers: answers, id: id } });
  };

  const isAnyCheckboxSelected = answers[Object.keys(questions)[0]]?.length > 0;

  return (
    <>
      <header className="veriti-header">
        <Link to="/welcome"><img className="img-fluid" src={logo} /></Link>
        <ul className="veriti-stepper">
          <li className="active">
            <span>1</span>
            <h5>Company Summary</h5>
          </li>
          <li className="active">
            <span>2</span>
            <h5>Questionnaire 1</h5>
          </li>
          <li className="active">
            <span>3</span>
            <h5>Questionnaire 2</h5>
          </li>
          <li>
            <span>4</span>
            <h5>Cybersecurity Report</h5>
          </li>
        </ul>
        <Link to="/login"><img className="img-fluid" src={logout} /></Link>
      </header>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
          }}
        >
          <Modal show={loading} centered>
            <Modal.Body>
              <h3>Analyzing and generating new questions</h3>
              <p>Please wait...</p>
              {/* <h5>
                {countdown}
                <span> sec</span>
              </h5> */}
              <div className="mt-4">
                <ProgressBar animated now={percentage}/>
              </div>
            </Modal.Body>
          </Modal>
          <Spin spinning={loading} tip="Loading..." size="large"></Spin>
          {!isLastQuestion && (
            <p style={{ marginTop: "13px" }}>
              Analyzing and generating new question
            </p>
          )}
        </div>
      ) : (
        <div className="veriti-section-wrapper veriti-questionary-wrapper ">
          <div className="veriti-section-inner veriti-question-wrapper">
            <div className="container">
              <div className="content-box p-0">
                <div className="editable-summary-container editable-summary-container-full">
                  <div className="summary-content-head">
                    <div className="question-tab">
                      <div className="ques-tab">
                        Question: <span>{questionCount}</span>
                      </div>
                      {confidenceScore && (
                        <div className="score-tab">
                          Debug Confidence Score: <b>{confidenceScore}</b>
                        </div>
                      )}
                    </div>
                    <h3>{Object.keys(questions)[0]}</h3>
                  </div>
                  <Checkbox.Group
                    value={answers[Object.keys(questions)[0]] || []}
                    onChange={(checkedValues) =>
                      setAnswers({
                        ...answers,
                        [Object.keys(questions)[0]]: checkedValues,
                      })
                    }
                  >
                    <Col>
                      {Array.isArray(questions[Object.keys(questions)[0]]) ? (
                        questions[Object.keys(questions)[0]].map(
                          (option, optionIndex) => (
                            <Row key={optionIndex} span={8}>
                              <Checkbox
                                value={option}
                                onChange={() => handleCheckboxChange(option)}
                              >
                                <label>{option}</label>
                              </Checkbox>
                              {
                                //SHOW A TEXTBOX IF OTHER IS SELECTED
                                option === "Other" &&
                                  answers[questions]?.includes("Other") && (
                                    <Input.TextArea
                                      placeholder="Please specify"
                                      value={
                                        otherAnswers[questions]
                                          ? otherAnswers[questions]
                                          : ""
                                      }
                                      maxLength={500}
                                      onChange={(e) => {
                                        setOtherAnswers((prevOtherAnswers) => ({
                                          ...prevOtherAnswers,
                                          [questions]: e.target.value,
                                        }));
                                      }}
                                    />
                                  )
                              }
                            </Row>
                          )
                        )
                      ) : (
                        <p>Please provide options for this question.</p>
                      )}
                    </Col>
                  </Checkbox.Group>
                </div>
              </div>
              <div className="content-box p-0">
                <div className="button-container">
                  <div className="btn-wrapper w-100 justify-content-between border-0">
                    {!isLastQuestion ? (
                      <>
                        <Button
                          type="primary"
                          className="btn-veriti btn-veritig-white  ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary btn-veriti"
                          onClick={handlePrev}
                        >
                          <span className="prev-white"></span>
                          Prev
                        </Button>
                        <Button
                          type="primary"
                          className="btn-veritig-white  ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary btn-veriti"
                          onClick={handleNext}
                          disabled={!isAnyCheckboxSelected}
                        >
                          <span>Next </span>
                          <span className="next"></span>
                        </Button>
                      </>
                    ) : (
                      <Button
                        type="primary"
                        onClick={handleNext}
                        className="btn-veritig-white d-flex ms-auto me-0 ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary btn-veriti"
                      >
                        Generate Final Summary
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="manipulation-buttons">
              {/* <Button type="primary" onClick={handleRemoveQuestion}>
                                Remove
                            </Button> */}
            </div>
          </div>
        </div>
      )}
      <Modal
        title="Add New Question"
        visible={modalVisible}
        onOk={handleSaveQuestion}
        onCancel={() => setModalVisible(false)}
      >
        <Input
          placeholder="Enter question"
          value={newQuestion}
          onChange={(e) => setNewQuestion(e.target.value)}
        />
        <div>
          <p></p>
          {newOptions.map((option, index) => (
            <Row key={index} span={8}>
              <Input
                placeholder={`Option ${index + 1}`}
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
              />
            </Row>
          ))}
          <Button type="primary" onClick={handleAddOption}>
            Add Option
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default QuestionnaireII;
